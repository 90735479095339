import React from 'react';
import {CookieNotice} from "gatsby-cookie-notice";

export default function Cookie() {
  return (
    <CookieNotice
      personalizeButtonClasses={"btn-classes-cookies"}
      personalizeButtonText={""}
      acceptButtonText={"Aceptar"}
      declineButtonText={"Rechazar"}
      >
        <div className="cookie-content">
          <h4 className="cookie-title">Políticas de cookies</h4>
          <p className="cookie-text">Usamos cookies para mejorar su experiencia de navegación y analizar nuestro tráfico. Al hacer clic en “Aceptar” usted da su consentimiento a nuestro uso de las cookies.</p>
        </div>
    </CookieNotice>
  );
}