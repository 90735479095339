import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import * as React from 'react';
import LayoutGeneral from '../components/organisms/Layout/LayoutGeneral';
import ModalProyecto from '../components/organisms/Modal/ModalProyecto';
import TabsFundamentaMas from '../components/template/FundamentaMas/TabsFundamentaMas';
import NuestraInicio from '../components/template/Inicio/NuestraInicio.js';
import BannerEstilo3 from '../components/template/Proyecto/BannerEstilo3';
import BannerMovilProyecto from '../components/template/Proyecto/BannerMovilProyecto';
import BannerProyecto from '../components/template/Proyecto/BannerProyecto';
import CotizarProyecto from '../components/template/Proyecto/CotizarProyecto';
import DescripcionProyecto from '../components/template/Proyecto/DescripcionProyecto';
import FormularioProyecto from '../components/template/Proyecto/FormularioProyecto';
import MapaProyecto from '../components/template/Proyecto/MapaProyecto';
import OtrosProyecto from '../components/template/Proyecto/OtrosProyecto';
import RecorridoProyecto from '../components/template/Proyecto/RecorridoProyecto';
import SalaVentasProyecto from '../components/template/Proyecto/SalaVentasProyecto';
import TrabajamosCon from '../components/template/Proyecto/TrabajamosCon';
import Ubicaciones from '../components/template/Proyecto/Ubicaciones';

export const query = graphql`
  query ($id: ID!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wordpress {
      page(id: "445", idType: DATABASE_ID) {
        databaseId
        title
        template {
          ... on Wordpress_Template_ProyectosEnVenta {
            proyectosEnVenta {
              tituloCotizar
              tituloRecorrido
              tituloSalaDeVentas
              ubicacionDeFundamenta {
                longitude
                latitude
              }
            }
          }
        }
      }
      inicio: page(id: "26", idType: DATABASE_ID) {
        title
        template {
          ... on Wordpress_Template_Inicio {
            templateName
            inicio {
              tituloNuestraExperiencia
              detalleNuestraExperiencia {
                numero
                texto
                imagen {
                  ...imagen
                }
              }
            }
          }
        }
      }
      proyecto(id: $id, idType: DATABASE_ID) {
        databaseId
        title
        uri
        featuredImage {
          node {
            ...imagen
          }
        }
        distritos {
          nodes {
            name
          }
        }
        dormitorios {
          nodes {
            name
          }
        }
        estados {
          nodes {
            databaseId
            name
          }
        }
        template {
          ... on Wordpress_Template_ProyectoEnVenta {
            proyectoEnVenta {
              estiloUbicaciones
              tituloUbicaciones
              urlGoogleMapsUbicaciones
              urlWazeUbicaciones
              textoUbicaciones
              mapaUbicaciones {
                iconoBoton {
                  ...imagen
                }
                iconoBotonHover {
                  ...imagen
                }
                imagen {
                  ...imagen
                }
                principal
                texto
              }
              trabajamosConTitulo
              trabajamosConLogos {
                id
                sourceUrl
                altText
              }
              ocultarCotizador
              ocultarFormulario
              idFormulario
              dataAdicionalPortada
              quitarInmueblesCotizador
              textoBotonTourAdicionaDescription
              precioDescripcion
              textoAvanceDeObraDescription
              ocultarCamposBrochureAreaDormitorios
              paginaBrochure {
                ... on Wordpress_Pdf {
                  uri
                }
              }
              paginaAvanceDeObraDescription {
                ... on Wordpress_Page {
                  uri
                }
              }
              paginaDeTourAdicionalDescripcion {
                ... on Wordpress_Page {
                  uri
                }
              }
              paginaDeTourDescripcion {
                ... on Wordpress_Page {
                  uri
                }
              }
              tipoBannerPortada
              estilo3ImagenFondoDesktopPortada {
                ...imagen
              }
              estilo3ImagenFondoMobilePortada {
                ...imagen
              }
              estilo3ImagenSecundariaDesktopPortada {
                ...imagen
              }
              estilo3ImagenSecundariaMobilePortada {
                ...imagen
              }
              urlVideoPortada
              areaDescripcion
              brochureDescripcion {
                mediaItemUrl
              }
              correoEvoltaCotizador
              diasAtencionVentas
              direccionDescripcion
              horarioAtencionVentas
              direccionAtencionVentas
              idProyectoCotizador
              activarModal
              textoFundamentaMas
              imagenModal {
                ...imagen
              }
              imagenMovilPortada {
                ...imagen
              }
              imagenPortada {
                ...imagen
              }
              imagenesDisenoMas {
                texto
                imagen {
                  ...imagen
                }
              }
              imagenesEspaciosMas {
                imagen {
                  ...imagen
                }
                texto
              }
              jefeDeVentas {
                correo
                foto {
                  ...imagen
                }
                cargo
                nombre
                telefono
                idWsp
              }
              lanzamientoDescripcion
              logoPortada {
                ...imagen
              }
              paginaDeGraciasCotizador {
                ... on Wordpress_Page {
                  id
                  uri
                }
              }
              urlRecorrido
              textoBotonTourDescription
              ubicaciones {
                texto
                ubicacion {
                  longitude
                  latitude
                }
              }
            }
          }
        }

        seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
            altText
            sourceUrl
            srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
            altText
            sourceUrl
            srcSet
          }
          canonical
          cornerstone
          schema {
            articleType
            pageType
            raw
          }
        }
      }
    }
  }
`;

export default function ProyectoEnVenta({ data }) {
  const lineaCss = css`
    height: calc(100vw * (calc(3 / var(--width_base))));
    width: 100%;
    background-color: #93ba1f;
  `;
  const nuestraInicioCss = css`
    background-color: #535353;
    @media (max-width: 767px) {
      background-color: #535353;
    }
  `;
  const siteUrl = data?.site?.siteMetadata?.siteUrl;
  const proyectoEnVenta = data?.wordpress?.page?.template?.proyectosEnVenta;
  const page = data?.wordpress?.proyecto;
  const dataDistrito = page?.distritos?.nodes;
  const distrito = page?.distritos?.nodes[0]?.name;
  const estado = page?.estados?.nodes[0]?.name;
  const estadoId = page?.estados?.nodes[0]?.databaseId;
  const dataDormitorios = page?.dormitorios?.nodes;
  const dormitorios = dataDormitorios;
  const template = page?.template?.proyectoEnVenta;
  let newDormitorios = [];
  dormitorios.filter((a) => newDormitorios.push(a.name));
  const dormitorio = Math.max(...newDormitorios);
  const inicio = data?.wordpress?.inicio?.template?.inicio;

  return (
    <LayoutGeneral
      cotizar={true}
      isProyecto={true}
      title={page?.title}
      slug={page?.uri}
    >
      <Seo post={page} />
      <h1 className="d-none">{page.title}</h1>
      {template?.activarModal && template?.imagenModal && (
        <ModalProyecto image={template?.imagenModal} />
      )}
      {template?.tipoBannerPortada === 'imagen' ||
      template?.tipoBannerPortada === 'video' ? (
        <>
          <BannerProyecto
            imagen={template?.imagenPortada}
            urlVideo={template?.urlVideoPortada}
            tipo={template?.tipoBannerPortada}
            logo={template?.logoPortada}
            distrito={distrito}
            dataAdicional={template?.dataAdicionalPortada}
            className="only-desk"
            titlePage={page.title}
          />
          <BannerMovilProyecto
            dormitorios={dataDormitorios}
            distritos={dataDistrito}
            urlVideo={template?.urlVideoPortada}
            imagenFondo={template?.imagenMovilPortada}
            logo={template?.logoPortada}
            className="only-mov"
            tipo={template?.tipoBannerPortada}
            titlePage={page.title}
          />
        </>
      ) : (
        <BannerEstilo3
          imagenFondoDesktop={template?.estilo3ImagenFondoDesktopPortada}
          imagenFondoMobile={template?.estilo3ImagenFondoMobilePortada}
          imagenSecundariaDesktop={
            template?.estilo3ImagenSecundariaDesktopPortada
          }
          imagenSecundariaMobile={
            template?.estilo3ImagenSecundariaMobilePortada
          }
        />
      )}
      <DescripcionProyecto
        ocultarCampos={template?.ocultarCamposBrochureAreaDormitorios}
        distrito={distrito}
        direction={template?.direccionDescripcion}
        maxDormitorio={dormitorio}
        dormitorios={dataDormitorios}
        status={estado}
        estadoId={estadoId}
        area={template?.areaDescripcion}
        // linkPdf={template?.brochureDescripcion?.mediaItemUrl}
        linkPdf={template?.paginaBrochure?.uri}
        linkTour={template?.paginaDeTourDescripcion?.uri}
        isAnimado={true}
        titlePage={page.title}
        textTour={template?.textoBotonTourDescription}
        linkTour2={template?.paginaDeTourAdicionalDescripcion?.uri}
        textTour2={template?.textoBotonTourAdicionaDescription}
        textAvance={template?.textoAvanceDeObraDescription}
        linkAvance={template?.paginaAvanceDeObraDescription?.uri}
      />

      {inicio && (
        <NuestraInicio
          data={inicio?.detalleNuestraExperiencia}
          title={inicio?.tituloNuestraExperiencia}
          titlePage={page.title}
          styleCss={nuestraInicioCss}
        />
      )}
      <div css={lineaCss} className="only-mov" />
      <TabsFundamentaMas
        dataEspacios={template?.imagenesEspaciosMas}
        dataDiseno={template?.imagenesDisenoMas}
        isProyecto={true}
        isAnimado={true}
        titlePage={page.title}
        texto={template?.textoFundamentaMas}
      />
      {template?.trabajamosConTitulo && (
        <TrabajamosCon
          dataTitulo={template?.trabajamosConTitulo}
          dataImagenes={template?.trabajamosConLogos}
        />
      )}

      {
        //!(estadoId === 106 || estadoId === 1365) && (
        true && (
          <>
            {template?.idProyectoCotizador && !template?.ocultarCotizador && (
              <CotizarProyecto
                siteUrl={siteUrl}
                proyecto={page.title}
                title={proyectoEnVenta?.tituloCotizar}
                idProyecto={template?.idProyectoCotizador}
                titlePage={page.title}
                correoEvolta={template?.correoEvoltaCotizador}
                paginaGracias={template?.paginaDeGraciasCotizador?.uri}
                quitarInmuebles={template?.quitarInmueblesCotizador}
              />
            )}
            {template?.idFormulario && !template?.ocultarFormulario && (
              <FormularioProyecto
                titlePage={page.title}
                idFormulario={template?.idFormulario}
                siteUrl={siteUrl}
                paginaGracias={template?.paginaDeGraciasCotizador?.uri}
              />
            )}
          </>
        )
      }
      {template?.urlRecorrido && (
        <RecorridoProyecto
          title={proyectoEnVenta?.tituloRecorrido}
          url={template?.urlRecorrido}
          titlePage={page.title}
        />
      )}
      {!(estadoId === 1365) && (
        <>
          <SalaVentasProyecto
            data={template?.jefeDeVentas}
            title={proyectoEnVenta?.tituloSalaDeVentas}
            dias={template?.diasAtencionVentas}
            horario={template?.horarioAtencionVentas}
            direccion={template?.direccionAtencionVentas}
            titlePage={page.title}
          />
        </>
      )}

      {template?.estiloUbicaciones === 'predeterminado' ? (
        <>
          {proyectoEnVenta?.ubicacionDeFundamenta?.latitude &&
            proyectoEnVenta?.ubicacionDeFundamenta?.longitude &&
            template?.ubicaciones && (
              <MapaProyecto
                latitud={proyectoEnVenta?.ubicacionDeFundamenta?.latitude}
                longitud={proyectoEnVenta?.ubicacionDeFundamenta?.longitude}
                data={template?.ubicaciones}
                titlePage={page.title}
              />
            )}
        </>
      ) : (
        <>
          <Ubicaciones
            estilo={template?.estiloUbicaciones}
            titulo={template?.tituloUbicaciones}
            urlGoogle={template?.urlGoogleMapsUbicaciones}
            urlWaze={template?.urlWazeUbicaciones}
            texto={template?.textoUbicaciones}
            ubicaciones={template?.mapaUbicaciones}
          />
        </>
      )}

      <OtrosProyecto
        idProyecto={page?.databaseId}
        className="only-desk"
        titlePage={page.title}
      />
    </LayoutGeneral>
  );
}
